@mixin input {
  border-bottom: 1px solid $light-blue;
  color: $beige25;
  font-size: rem(18px);

  &--error,
  &--error:focus,
  &:invalid,
  &:invalid:focus {
    border-bottom: 1px solid $bright-orange;
  }

  &:not(&--error) {
    &:focus {
      border-bottom: 1px solid $light-blue;
    }
  }

  &::placeholder {
    color: $beige60;
  }

  &:focus {
    background-color: lighten($dark-blue-transparent, 5%);
  }

  @include font-smoothing;
  display: block;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  @include input;
  -webkit-text-fill-color: $beige25 !important;
  -webkit-box-shadow: 0 0 0 30px $dark-blue-transparent inset !important;
}
