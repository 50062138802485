.voucher-form {
  &__section {
    @include flex-1-col-center-center;
  }

  &__cta-btn {
    @include button-orange;
    margin-bottom: rem(50px);
  }

  &__text-link {
    @extend .paragraph;
    @include link;
  }

  &__form {
    width: 90%;
    @include respond-min($breakpoint-tablet) {
      width: 70%;
    }
  }

  &__wrapper-input {
    @extend .wrapper__input;
    height: unset;
  }

  &__input {
    font-family: $font-sans-pro;
    background-color: $blue-greyish;
    @include coupon_field;
    padding-right: rem(10px);
    padding-left: rem(25px);
    text-align: left;
    @include respond-min($breakpoint-tablet) {
      border-radius: rem(50px);
      padding-right: rem(25px);
      padding-left: rem(25px);
    }

    &::placeholder {
      text-align: left;
    }
  }

  &__paragraph {
    @extend .paragraph;
    width: 80%;
    @include respond-min($breakpoint-tablet) {
      min-width: 100%;
    }
  }

  &__subtext {
    color: var(--sleep-beige-c100);
    font-weight: $font-weight-light;
    margin-bottom: rem(14px);
    font-size: rem(18px);
    line-height: rem(23px);
    letter-spacing: 0.15px;
    @include font-smoothing;
    margin-bottom: rem(28px);

    @include respond-min($breakpoint-desktop) {
      font-size: rem(16px);
      line-height: rem(20px);
      margin-bottom: rem(32px);
    }
  }

  &__invalid-feedback {
    color: $error-red;
    margin-top: rem(-8px);
    margin-left: rem(20px);
    @include respond-min($breakpoint-tablet) {
      margin-top: rem(8px);
      margin-left: rem(25px);
    }
  }

  &__heading {
    @extend .heading;
    width: 80%;
    @include respond-min($breakpoint-tablet) {
      min-width: 100%;
    }
  }
}
