.main {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: $dark-blue;
  align-items: center;
  background-image: url(../../img/bgr_waves_mobile.svg);
  background-repeat: no-repeat;
  background-size: cover;
  // footer with cta
  min-height: calc(100vh - 230px);
  padding-left: rem(10px);
  padding-right: rem(10px);
  @include respond-min($breakpoint-tablet) {
    background-image: url(../../img/bgr_waves.svg);
  }
  // account for reduced footer height without cta
  &--no-footer-cta {
    min-height: calc(100vh - 185px);
    @include respond-min($breakpoint-tablet) {
      min-height: calc(100vh - 90px);
    }
  }

  &__wrapper--blue-bg {
    background-color: $darker-blue-transparent;
    padding-top: rem(60px);
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
}
