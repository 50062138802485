.notification {
  &-overlay {
    @include flex-1-col-center-start;
    background-color: $overlay-blue;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    padding: rem(110px) 0 0;
    opacity: 1;
    transition: opacity 500ms ease-in 1.5s;

    @include respond-min($breakpoint-tablet) {
      align-items: center;
      padding: rem(90px) 0 0;
    }

    &--is-hidden {
      opacity: 0;
      transition: opacity 500ms ease-out;
    }
  }

  &-box {
    position: fixed;
    text-align: center;
    width: 90%;
    height: 85%;
    z-index: 10;
    box-shadow: 0px 0px 15px 15px rgba(18, 24, 31, 0.4);
    padding: 0 !important;
    background-color: $darker-blue-transparent-97;
    border-radius: rem(5px);

    @include respond-min($breakpoint-tablet) {
      padding: rem(0px) 0 0 rem(30px) !important;
      max-height: rem(720px);
    }

    @include respond-min($breakpoint-desktop) {
      max-width: rem(1050px);
    }

    &__close-btn {
      @include bg-contain-center;
      z-index: 11;
      position: fixed;
      width: rem(20px);
      height: rem(20px);
      position: absolute;
      top: rem(15px);
      right: rem(15px);
      background-image: url("../../../img/icon_close.svg");
      background-color: $footer-blue;
      &:hover {
        opacity: 0.5;
      }
    }

    &__scroll-view {
      @include scrollbar;
      @include flex-1-col;
      height: 100%;
      overflow-y: scroll;

      @include respond-min($breakpoint-tablet) {
        overflow: auto;
      }
    }

    &__content-wrapper {
      @include flex-1-col-center-start;
      margin: rem(40px) rem(10px) rem(30px) rem(10px);
      padding-right: rem(10px) rem(35px) 0 rem(10px);

      @include respond-min($breakpoint-tablet) {
        margin: rem(70px) rem(10px) 0 rem(10px);
        padding: 0 rem(10px);
      }
      @include respond-min($breakpoint-desktop) {
        margin: rem(80px) rem(10px) 0 rem(10px);
      }
    }

    &__link-cta {
      @include button-orange;
      padding: rem(10px) rem(20px);
      text-align: center;
      min-width: rem(200px);
      align-self: center;

      @include respond-min($breakpoint-tablet) {
        max-width: rem(400px);
      }
    }

    &__heading-5 {
      @extend .paragraph;
      font-family: $font-sans-pro;
      font-weight: $font-weight-normal;
      margin: 0 0 !important;
      display: inline;
    }

    &__subtext {
      font-weight: $font-weight-light;
      font-size: rem(16px);
      color: var(--sleep-beige-c100);
      @include font-smoothing;
      margin-top: rem(18px);
      letter-spacing: 1.9;
      z-index: 5;

      a {
        border-bottom: 0.5px solid $beige20;
        &:hover {
          opacity: 0.5;
        }
      }
    }

    .freddy {
      width: 120px;
      height: auto;

      @include respond-min($breakpoint-tablet) {
        width: 160px;
      }

      @include respond-min($breakpoint-desktop) {
        width: 180px;
      }
    }

    .center {
      flex: 1;
    }
    .blank,
    .img-wrapper {
      flex: 1;
    }
  }
}
