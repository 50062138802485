.StripeElement {
  min-height: rem(24px);
  flex: 1;
  background-color: transparent;
  color: $beige25;
  border-bottom: 1px solid $light-blue;

  &__label {
    @include label;
  }

  &__wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &--invalid {
    border-bottom-color: $bright-orange;
  }
}
