.pricing {
  &__btn--orange {
    @include button-orange-in-form;
    font-family: $font-sans-pro;
    font-size: 1rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: rem(0.6px);
    text-align: center;
    color: $beige25;
    @media screen and (max-width: $breakpoint-desktop-large) {
      padding-left: 4.25rem;
      padding-right: 4.25rem;
    }
  }

  &__btn--blue {
    @include button-blue-in-form;
    font-family: $font-sans-pro;
    font-size: 1rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: rem(0.6px);
    text-align: center;
    color: $beige25;
    @media screen and (max-width: $breakpoint-desktop-large) {
      padding-left: 4.25rem;
      padding-right: 4.25rem;
    }
  }
  .strikethrough-price {
    font-family: $font-sans-pro;
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $beige25;
    &:after {
      content: "";
      display: block;
      width: 6.5rem;
      height: rem(6px);
      margin: auto;
      -webkit-transform: translateY(-0.9em) rotate(-13deg);
      transform: translateY(-0.9em) rotate(-13deg);
      box-shadow: 0 1px 4px 0 rgba(18, 24, 31, 0.25);
      background-image: linear-gradient(94deg, $light-blue, $blue-greyish);
      top: rem(7px);
      position: relative;
    }
  }
  .box__payment__highlight {
    .strikethrough-price {
      &:after {
        background-image: linear-gradient(93deg, $bright-orange, #803d25),
          linear-gradient(to right, #305299 0%, #12181f 100%);
      }
    }
  }

  .success-feedback {
    padding: rem(13px) rem(4px);
    border-radius: rem(55px);
    box-shadow: 0 1px 5px 0 rgba(18, 24, 31, 0.25);
    border: solid 1px $light-blue;
    font-family: $font-sans-pro;
    text-transform: uppercase;
    font-size: rem(16px);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.72px;
    text-align: center;
    color: $light-blue;

    @include respond-min($breakpoint-tablet) {
      padding: rem(13px) rem(79px);
    }
  }
  .invalid-feedback {
    color: #8e4738;
    margin-top: 0.5rem;
    margin-left: 0.8rem;
  }

  &__link {
    @include link;
    color: $beige25;
    font-weight: $font-weight-light;
  }
}
