.footer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $footer-blue;
  padding: 0 rem(16px);

  &__branding {
    background-color: $blue-greyish;
    align-items: center;


    &--dark-blue {
      background-color: $footer-blue;
      width: 100%;
    }
  }

  &__branding-wrapper {
    max-width: rem(940px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include respond-min($breakpoint-tablet) {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__logo {
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: rem(12px);
    @include respond-min($breakpoint-tablet) {
      margin-bottom: 0;
      &:not(:last-of-type) {
        margin-right: rem(30px);
      }
    }
    &--brigitte {
      height: rem(25px);
      width: rem(90px);
      @include responsive-background-image("logo_brigitte", "png");
    }
    &--flow {
      height: rem(27px);
      width: rem(57px);
      @include responsive-background-image("logo_flow", "png");
    }
    &--balloon {
      height: rem(30px);
      width: rem(100px);
      background-image: url(../../img/logo_balloon.svg);
    }
  }

  &__logo-holder {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-bottom: rem(20px);
    @include respond-min($breakpoint-tablet) {
      margin-bottom: 0;
      align-items: center;
      flex-direction: row;
    }
  }

  &__link {
    font-family: $font-sans-pro;
    font-weight: normal;
    font-size: rem(16px);
    text-decoration: underline;
    color: $beige50;
    margin-bottom: rem(12px);
    @include respond-min($breakpoint-tablet) {
      &:not(:last-of-type) {
        margin-right: rem(18px);
      }
    }
    @include font-smoothing;
    @include on-interaction {
      text-decoration: none;
    }
  }

  &__link-cta {
    @extend .link__cta;
    @include respond-min($breakpoint-phablet) {
      padding: rem(15px) rem(40px);
    }
  }

  &__link-holder {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: rem(30px);
    @include respond-min($breakpoint-tablet) {
      flex-direction: row;
    }
  }
}
