$beige10: #f9f7f1;
$beige20: #f3f0e4;
$beige25: #f3f0e5;
$beige50: #ece3c4;
$beige60: #b2b3ae;
$light-blue: #7699cb;
$dark-blue: rgb(23, 32, 42);
$dark-blue-transparent: rgba(31, 48, 72, 0.9);
$darker-blue-transparent: rgba(27, 39, 55, 0.6);
$darker-blue-transparent-97: rgba(27, 39, 55, 0.97);
$bright-orange: #e06f4c;
$dark-orange: #803d25;
$blue-greyish: #243a5e;
$footer-blue: #1b2737;
$error-red: #c5603e;
$overlay-blue: rgba(18, 24, 31, 0.45);
