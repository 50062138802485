.resend {
  &__wrapper {
    @include wrapper;
    &__flex-col {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 100%;
      @include respond-min($breakpoint-tablet) {
        width: unset;
      }
    }
  }
  &__paragraph {
    @include paragraph;
    max-width: rem(768px);
    text-align: center;
  }

  &__box {
    @include box-rounded;
    padding-top: rem(30px);
    padding-bottom: rem(50px);
    margin-top: rem(30px);
    @include respond-min($breakpoint-tablet) {
      padding-left: rem(30px);
      padding-right: rem(30px);
    }
  }

  &__input {
    @include coupon_field;
    background-color: $blue-greyish;
    padding: rem(16px);
    min-width: rem(300px);
    text-align: center;
    @include respond-min($breakpoint-tablet) {
      min-width: rem(500px);
      padding: rem(16px);
    }
  }
}
