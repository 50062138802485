@mixin scrollbar($size: 3px) {
  // For Chrome & Safari
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }
  &::-webkit-scrollbar-thumb {
    background: $blue-greyish;
  }
  &::-webkit-scrollbar-track {
    background: $footer-blue;
  }

  // Standard version (Firefox only for now)
  scrollbar-color: $blue-greyish $footer-blue;
}
