.terminate-success {
  .wrapper {
    position: relative;
  }
  &__wrapper-lg-w50 {
    position: relative;
    z-index: 1;
    @include box-rounded;
    background-color: $darker-blue-transparent;
    padding: rem(32px) rem(16px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @include respond-min($breakpoint-tablet) {
      flex-basis: 50%;
    }
    min-height: rem(363px);
  }
  .paragraph {
    padding: 0 rem(30px);
    @include respond-min($breakpoint-tablet) {
      padding: 0;
    }
  }

  .imprint__image-freddy-laeuft {
    background-image: url("../../img/terminate/freddy-laeuft-terminate.svg");
    left: 50%;
    transform: translateX(-50%);
    min-height: rem(78px);
    width: 100%;
    max-width: rem(393px);
    bottom: -0.5rem;
  }
  .cta-pdf {
    @extend .link__cta;
    max-width: rem(275px);
    width: 100%;
    align-self: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(65px);
    white-space: break-spaces;
    padding-left: rem(20px);
    padding-right: rem(40px);
    @include respond-min($breakpoint-tablet) {
      margin-bottom: rem(55px);
      max-width: rem(525px);
      white-space: nowrap;
      padding-left: rem(80px);
      padding-right: rem(80px);
    }
  }
}
