.header {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $dark-blue-transparent;
  padding-left: rem(16px);
  padding-right: rem(16px);

  @include font-smoothing;

  &__link {
    font-family: $font-sans-pro;
    font-size: rem(14px);
    font-weight: $font-weight-bold;
    letter-spacing: rem(0.8px);
    &--active {
      color: #f3f0e5;
    }
    &--expanded {
      font-weight: $font-weight-semibold;
      font-size: rem(20px);
      &:not(:last-of-type) {
        margin-bottom: rem(40px);
      }
    }
    @include respond-min($breakpoint-tablet) {
      &:not(:last-of-type) {
        margin-right: rem(40px);
      }
    }
    @include on-interaction {
      color: #c9c6b4;
    }
  }

  &__link-logo {
    flex: 1;
    display: flex;
  }

  &__image-logo {
    flex: 1;
    height: rem(61px);
    background-image: url("../../img/7schlaefer-logo.svg");
    background-repeat: no-repeat;

    @include respond-min($breakpoint-tablet) {
      height: rem(72px);
      background-position-x: rem(-20px);
    }
  }

  &__navigation {
    display: none;
    color: $beige50;
    text-transform: uppercase;
    @include respond-min($breakpoint-tablet) {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
    &--expanded {
      position: absolute;
      top: rem(60px);
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: $dark-blue-transparent;
      padding: rem(40px);
      @include respond-min($breakpoint-tablet) {
        display: none;
      }
    }
  }

  &__menu-toggle {
    margin-left: auto;
    margin-bottom: rem(-6px);
    outline: 0;
    @include respond-min($breakpoint-tablet) {
      display: none;
    }
  }

  &__wrapper {
    @include wrapper;
    @include respond-max($breakpoint-tablet - rem(1px)) {
      padding: 0;
    }
    &__max--width {
      max-width: 68rem;
    }
  }
}

.hamburger:focus {
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
