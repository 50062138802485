.wrapper {
  @include wrapper;
  padding: 0 1rem;
  &__box {
    display: flex;
    align-items: center;
    @include respond-max($breakpoint-phone-xs) {
      padding: 0;
    }
  }
}
.wrapper__offers {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  margin-bottom: rem(35px);
  width: 100%;
  @include respond-min($breakpoint-tablet) {
    justify-content: space-around;
    width: unset;
  }
  @include respond-min($breakpoint-desktop) {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    margin-top: rem(20px);
  }
}

.wrapper__input {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-top: rem(10px);
  width: 100%;

  @include respond-min($breakpoint-tablet) {
    height: 3em;
    align-items: unset;
    flex-flow: row nowrap;
  }

  .coupon_field,
  .coupon_button {
    font-family: $font-sans-pro;
    background-color: $blue-greyish;
  }

  .coupon_field {
    @include coupon_field;
  }

  .coupon_button {
    flex-shrink: 0;
    margin: 0;
    font-size: rem(16px);
    color: var(--sleep-beige-c100);
    padding-left: 1rem;
    padding-right: 1rem;
    @include respond-min($breakpoint-tablet) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
